<template>
  <div class="batchBox">
    <el-row class="accountCon">
      <el-col class="leftCon" :span="4">
        <div class="label1">
          <i class="el-icon-collection-tag" /> 仓库信息
          <img src="@/assets/images/triangle.png" alt />
        </div>
      </el-col>
      <el-col class="rightCon" :span="24">
        <el-form
          ref="detail"
          label-position="right"
          label-width="140px"
          :rules="rules"
          :model="detail"
        >
          <el-form-item label="仓库名称:" prop="name">
            <el-input v-model="detail.name" class="w300" placeholder="请输入仓库名称" />
          </el-form-item>
          <el-form-item label="归属市场" prop="marketId">
            <el-select
              v-model="detail.marketId"
              clearable
              class="w300"
              @change="changeMarket"
              placeholder="请选择统归属市场"
            >
              <el-option
                v-for="item in options.marketId"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="仓库金蝶编码:" prop="kingdeeCode">
            <el-input v-model="detail.kingdeeCode" class="w300" placeholder="请输入仓库金蝶编码" />
          </el-form-item>

          <el-button type="primary" size="mini" class="mainBtn" @click="submitForm('detail')">完成，提交</el-button>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  createShopAdminStoreResource,
  readAdminMarketList,
  readShopAdminStoreInfo,
  updateShopAdminStoreResource
} from "@/api/shop";
class Detail {
  name = "";
  marketId = "";
  marketName = "";
  kingdeeCode = "";
}
class Rules {
  name = [{ required: true, message: "请填写仓库名称", trigger: "blur" }];
  marketId = [{ required: true, message: "请选择归属市场", trigger: "change" }];
  kingdeeCode = [
    { required: true, message: "请填写仓库金蝶编码", trigger: "blur" }
  ];
}
class Options {
  constructor() {
    this.marketId = [];
  }
}
export default {
  name: "AddStorage",
  components: {},

  data() {
    return {
      detail: new Detail(),
      rules: new Rules(),
      options: new Options() // 选项
    };
  },
  mounted() {
    this.getOptions();
    this.$route.query.id ? this.getDetail(this.$route.query.id) : "";
  },
  methods: {
    // 获取选项
    getOptions() {
      readAdminMarketList().then(res => {
        this.options.marketId = res.list;
      });
    },
    // 改变市场
    changeMarket(e) {
      this.options.marketId.forEach(item => {
        if (e == item.id) {
          this.detail.marketName = item.name;
        }
      });
    },
    // 获取商品
    getDetail(id) {
      readShopAdminStoreInfo({ id: id }).then(res => {
        this.detail = res;
      });
    },
    // 确定新增商品
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.hint("add", "新建此仓库");
        } else {
          return false;
        }
      });
    },
    // 提示
    hint(type, text) {
      this.$confirm(`${text}，是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.confirm();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 确定新增/编辑
    confirm() {
      if (this.$route.query.id) {
        updateShopAdminStoreResource({
          ...this.detail,
          id: this.$route.query.id
        }).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.$router.push("./storageList");
        });
      } else {
        createShopAdminStoreResource({ ...this.detail }).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.$router.push("./storageList");
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.accountCon {
  background: #fff;
  position: relative;
  .leftCon {
    background: #fcfcfc;
    border-right: 1px solid #eee;
    position: absolute;
    height: 100%;
    > div {
      line-height: 48px;
      color: #fff;
      background: #9ea7b4;
      width: 100%;
      padding-left: 80px;
      font-size: 14px;
      box-sizing: border-box;
      img {
        position: absolute;
        right: -30px;
        width: 40px;
        height: 48px;
      }
    }
    .label1 {
      position: absolute;
      top: 40px;
    }
  }
  .rightCon {
    padding: 40px 0 0 300px;

    .mainBtn {
      margin: 20px 0 50px 240px;
    }
  }
}
</style>
